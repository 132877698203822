
import {
  computed, defineComponent,
} from 'vue'
import Page from '@/components/Page.vue'
import Button from '@/components/Form/Button.vue'
import DataTable, { Column } from '@/components/DataTable/DataTable.vue'
import { useStore } from 'vuex'
import { IHubAccount } from '@/types'
import PageTop from '@/components/PageTop.vue'
import Edit from '@/components/DataTable/Edit.vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  data(): { columns: Column[]} {
    return {
      columns: [
        {
          key: 'name',
          title: 'Name',
          canSort: true,
        },
        {
          key: 'url',
          title: 'URL',
          canSort: true,
        },
        {
          key: 'users',
          title: 'Users',
          canSort: true,
        },
        {
          key: 'edit',
          title: '',
        },
      ],
    }
  },
  components: {
    Page,
    Button,
    DataTable,
    PageTop,
    Edit,
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const { isAdmin } = store.getters
    const onAccountEditClick = (accountID: string) => {
      router.push({ name: 'HubAccount', params: { accountID } })
    }

    const rows = computed(() => store.getters['hubStore/accounts'].map((account: IHubAccount) => ({
      url: account.url,
      name: account.name,
      createdAt: account.createdAt,
      users: store.getters.usersByAccount(account.id).length,
      id: account.id,
    })))

    return {
      onAccountEditClick,
      isAdmin,
      rows,
    }
  },
})

